.mobile {
    display: none;
}
.filterssec{
    display: flex;
}
.liststu{
    display: flex;
    justify-content: space-around;
    gap: 90px;
}
.inertext {
    color: #2E2E2E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.28px;
}
@media (max-width:768px) {
    .desktop {
        display: none;
    }
    .mobilenav{
        width: 95vw;
    }
    .Inner_Content {
        width: 100vw;
    }
.filterssec{
    flex-direction: column;
    width: 90%;
    margin: auto;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-drawer .ant-drawer-header-title{
    display: flex;
    flex-direction: row-reverse;
}
:where(.css-dev-only-do-not-override-11xg00t).ant-space-gap-col-small {
    display: flex;
    justify-content: space-around;
}
.student_info{
    flex-direction: column;
}
.student_info_box{
    width: 95vw;
    margin: auto;

}
.AllStudent{
    width: 90vw;
    gap: 50px;
margin: auto;
}
.datepicker{
    width: 90vw !important;
}
    .mobile {
        display: block;
        padding-bottom: 24px;
    }
    .search_Box {
        width: 95vw !important;
        margin: auto;
    }
    .nav {
        display: flex;
        justify-content: space-between;
       margin-bottom: 24px;
       width: 100vw;
    }
    .studentinfo{
        flex-direction: row;
        margin-left: 0 !important;
    }
    /* .student_progress_bar{
        width: 95vw;
        margin: auto;
    } */
    .Student_Nav_btn{
        width: 95vw;
    }
    .tablebtn{
        width: 95vw;
        margin: 24px  auto  ;
        
    }
/* .studentinfo{
    margin-left: 0 !important;
    margin: auto;
    width: 95vw !important;
} */
    


    .Inner_Content {
        width: 100vw;
        margin-top: 0 !important;
    }
    .maincontainer{
        margin-top: 0 !important;
        width: 100vw !important;
    }
    .search_Box input{
        width: 100% !important;
    }
    .tablebtn2{
        display: flex;
    width: 95vw;
    margin: 20px auto;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #0A0A0A;
    }
    .tablewraper{
        width: 95vw;
        margin: auto;
        border-radius: 8px;
        border: 1px solid #F1F1F1;
        background: #FFF;
    }
    .inersteudentdetail{
        width: 85vw;
        margin: 8px auto;
        border-radius: 8px;
background: #F9F9FC;
padding: 8px;
margin-top: 15px;
    }
    .inersteudentdetail div{
        padding-bottom: 10px;
    }
    .inersteudentdetail div span:first-child{
        color: #696969;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
    }
    .inersteudentdetail div span:last-child{
        color: #0A0A0A;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 171.429% */
        padding-left: 8px;
    }
    .tablebtn3{
        display: flex;
width: 80vw;
margin: auto;
height: 32px;
padding: 12px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #0A0A0A;
    }

}