.AdminBody{
    background: #F9F9FC;
    display: flex;
   
}
.navbar_of_admin{
    width: 140px;
    background-color: #fff;
    height: 1000px;
    transition: width 1s ease-in-out; 
}
.main_bar_logo{
    display: flex;
    justify-content: center;
   
}

.navigation_btn{
    display: flex;
    margin-top: 57px;
    align-items: center;
    justify-content: flex-end;
}
.navigator{
   position: relative;
   left: 18px;
   cursor: pointer;
}
.pages_icons{
    display: flex;
flex-direction: column;
align-items:center;
gap: 24px;
margin-top: 67px;
}
.icons_container{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
display: flex;
align-items: center;
width: 200px;
gap: 10px;
transition: transform 1s ease-in-out;
cursor: pointer;
}
.icons_container svg{
    width: 20px;
    height: 20px;
}

.pages_icons svg{
    width: 32px;
    height: 32px;
}
.actived{
    border-radius: 8px;
    background: rgba(251, 183, 35, 0.20);
    padding: 5px;
}
.actived svg path{
    stroke: #FBB723;
}
.mainhome_dash{
    width: 84%;
   
}
.Inner_Content{
    width: 1061px;
    margin: auto;
    margin-top: 36px;
}
.search_Box{
    display: flex;
width: 1061px;
color: #696969;
height: 56px;
padding: 16px;
justify-content: space-between;
align-items: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
border-radius: 8px;
border: 1px solid #D8D4D5;
background: #F9F9FC;
}
.search_Box input{
    border: none;
    background-color: inherit;
    width: 800px;
}
.search_Box input:focus{
    outline: none;
    background-color: inherit;

}
.search_btn{
    color: #6F6B70;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 22.4px */
letter-spacing: 0.32px;
cursor: pointer;
}
.AllStudent{
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
}
.student_Data{
    color: #2C292A;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: 160%; /* 32px */
letter-spacing: 1px;
}
.see_more{
    color: #2C292A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
text-decoration-line: underline;
}
.student_info{
    display: flex;
    gap: 28px;
    margin-top: 14px;
}
.student_info_box{
height: 106px;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.25);
padding: 12px;
display: flex;
flex-direction: column;
gap: 3px;
}
.numberofstudent{
    color: #2C292A;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 32px */
letter-spacing: 1px;
}
.info_about_enrollment{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
}
.pagelocation{
    display: flex;
width: 140px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
border-radius: 50px;
background: #E9E9E9;
}
.addimage{
    color: #0A0A0A;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
text-decoration-line: underline;}
.allsugesstions{
    display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-radius: 20px;
border: 1px solid #D8D4D5;
background: #FFF;

/* Elevation-nivel-2 */
box-shadow: 0px 4px 12px 4px rgba(86, 86, 86, 0.16);
}
.everyitem{
    display: flex;
justify-content: space-between;
align-items: flex-start;
color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
width: 100%;
}
.showed{
    display: flex;
}
.hideed{
    display: none;
}
.drawerw .ant-drawer-wrapper-body{
    width: 100% !important;
}
.fortabelstudent {
    width: 96%;
    margin: auto;
    margin-top: 69px;
}
.fortabelstudent h2{
    margin-top: 40px;
}
@media (max-width:768px) {
    .Inner_Content{
        width: 100vw;
    }
    .navbar_of_admin {
        width: 90vw !important;
        margin: auto;
    }
    .icons_container{
        /* width: 50%; */
        /* margin: auto; */
        /* padding: 15px 0 15px 10px; */
    }
    :where(.css-dev-only-do-not-override-11xg00t).ant-drawer .ant-drawer-body {
        padding: 0;
    }
    .actived {
        padding: 10px 0 10px 10px;
    }
    .pages_icons{
        align-items: start;
        margin-top: 30px;
    }

    .AdminBody{
      flex-direction: column;
       
    }
}