.enrollmentpage{
display: flex;
width: 100%;
justify-content: center;
margin-top: 10vh;
padding: 30px;
}
.buttonoption{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
    align-items: center;
}
.btnforenroll{
    border: none;
    display: flex;
width: 334px;
height: 56px;
padding: 24px;
justify-content: center;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background: #FBB723;
}
.enrollment-heading{
    color: #0A0A0A;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
letter-spacing: 0.48px;
}
.addmission-position{
justify-content: center;
}
.conceller{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    color: #0A0A0A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;    
}