.loginform{
    width: 823px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.loginform input{
    display: flex;
    width: 608px;
height: 40px;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 8px;
border: 2px solid rgba(44, 41, 42, 0.25);
background: #FFF;
}
.inner_input_login{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  padding-top: 103px;
    margin: auto;
}
.mainlog img{
    width: 223px;
    height: 65px;
}
.login-form-container{
    margin-top: 80px;
}
.welcome{
    color: #FBB723;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 32px */
letter-spacing: 1px;
}
.logininfo{
    color: #2C292A;
font-family: Inter;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: 170%; /* 48px */
letter-spacing: 2px;

}
.formoflogin{
    margin-top: 36px;
}
.email{
    display: flex;
    flex-direction: column;
}
 label{
    color: #2C292A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
margin-top: 16px;
margin-bottom: 5px;
}
.password{
    margin-top:5px;
}
.controller input{
border: none;
width: 90%;
padding-left: 10px;
}
.controller{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
border: 2px solid rgba(44, 41, 42, 0.25);
background: #FFF;
padding: 0px;
padding-right: 10px;
   
}
.forgert_password{
display: flex;
color: #2C292A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
margin-top: 16px;
gap: 4px;
}
.resendemail{
    color: #FBB723;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;
text-decoration-line: underline;
}
.login_btn{
    display: flex;
width: 608px;
height: 56px;
/* padding: 169px 24px; */
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 8px;
background: #FBB723;
color: #2C292A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
border: none;
margin-top: 24px;
}

.forgert_attempt{
    margin-top: 24px;
    text-align: center;
}
.coverof_login{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.imageof_login {
    width: 900px;
}
.imageof_login img{
    width: 100%;
height: 100vh;
}
.forget_password{
    margin-top: 48px;
}
@media (max-width:1350px) {
    .imageof_login {
     display: none;
    }
    .loginform{
        width: 90vw;
        margin: auto;  
    }
}
@media (max-width:786px) {
    .loginform{
        width: 100vw;
        margin: auto;  
    }
    .mainlog{
        margin: auto;
    }
    .loginform input{
        width: 100%;
    }
    .form-control{
        width: 100%;
    }
    .login_btn{
        width: 100%;
    }
    .forgert_password{
        flex-direction: column;
    }
}