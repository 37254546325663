.maincontainer {
    width: 1061px;
    margin: auto;
    margin-top: 36px;
}

.table-wraper {
    border-radius: 16px !important;
}

th {
    color: #0A0A0A;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 153.846% */
}

td {
    color: #696969 !important;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 184.615% */
    /* padding: 12px 24px 12px 12px !important; */
    vertical-align: middle;
    padding-left: 12px;


}

.underline {
    text-decoration-line: underline;

}

.table-wraper table tr:last-child td {
    border-bottom: none;
}

.table-wraper table tr:last-child td:first-child {
    /* border-radius: 20px; */
    border-radius: 0 0 0 20px;
}

.table-wraper table tr:last-child td:last-child {
    /* border-radius: 20px; */
    border-radius: 0 0 20px 0;
}

.table-wraper div:first-child {
    /* border-radius: 20px; */
    border-radius: 20px 20px 0 0;
}

.table-wraper table tr {
    height: 80px;

}

.table-wraper table thead tr {
    height: 56px;
}

.tablebtn {
    display: flex;
    height: 40px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #FBB723;
    color: #2C292A;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.28px;
}


.tableheading {
    color: #0A0A0A;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 100% */
    letter-spacing: 0.48px;
}
.tableheading2{
    color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 171.429% */
letter-spacing: 0.28px;
}

.tableheading>span {

    color: #0A0A0A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.28px;
    padding-left: 24px;
}

.inertext {
    color: #2E2E2E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.28px;
}

.datepicker {
    width: 254px;
    height: 56px;
}

label {
    color: #0A0A0A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
}

.plaec {
    color: #696969;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
}

:where(.css-dev-only-do-not-override-11xg00t).ant-space-gap-col-small {
    gap: 140px;
}

.table-wraper table tr:nth-child(odd) td {
    background-color: #ffff;
    /* Set background color for odd rows */
}

.table-wraper table tbody tr:nth-child(even) td {
    background-color: #F9F9FC;
    /* Set background color for even rows */
}
.goback{
    margin-left: 80px;
    margin-top: 65px;

}
.employeenavb .main_student_nav{
    width: auto;
}
.studentinfo{
    width: 100%;
    display: flex;
    justify-content:space-around;
    align-items: flex-start;
    margin-top: 65px;
}
.goback {
    color: #696969;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.24px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.studentimagesection{
    margin: auto;
    display: flex;
flex-direction: column;
gap: 12px;
text-align: center;
}
.imageofstudent img{
    width: 131px;
    height: 131px;
}
.imageofstudent  svg{
    position: relative;
    top: 51px;
    right: 30px;
}
.name_of_student{
    color: #0A0A0A;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
letter-spacing: 0.48px;
}
.mail_of_student{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
letter-spacing: 0.28px;
}
.activecolor{
    color: #0A0A0A;

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
}
.activecolor2{
    color: #696969;

font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
}
.togletbtn{
    gap: 20px;
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: center;
}
.leftbar{
    width: 1px;
    height: 24px;
    
    background: #CBCBCB;
}
.mainheadermodal .ant-modal-content  {
   padding: 0 
}
.loginbtn {
    display: flex;
    margin-bottom: 20px;
    padding: 14px 18px;
    align-items: center;
    gap: 4px;
    justify-content: center;
    border-radius: 8px;
    background: #FBB723;
    color: #2C292A;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    margin-top: 10px;
    cursor: pointer;
    width: 564px;
}
.headermodal {
    display: flex;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px 16px 0px 0px;
border-bottom: 1px solid var(--Neutral-color-02, #F2F0EF);
/* padding-bottom: 20px; */


 
    /* Add a bottom border with the desired color */
  }
  .headermodalbody{
    padding: 20px 24px;
  }
  .headermodal div:first-child{
    color: #0A0A0A;

font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 32px; 
  }
.student_progress_bar{
    width: 740px;
height: 32px;
flex-shrink: 0;
border-radius: 4px;
background: #C9C8C5;
margin-top: 24px;
overflow: hidden;
}
.progress_percentage{
    color: #000;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 133.333% */
letter-spacing: 0.36px;
height: 32px;
vertical-align: middle;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
z-index: 9;
position: relative;
bottom: 32px;
}
.student_bar{
height: 32px;
flex-shrink: 0;
border-radius: 4px 0px 0px 4px;
background: #FFD880;
}
.Student_Nav_btn{
    display: flex;
width: 740px;
height: 56px;
padding: 0px 6px;
align-items: center;
gap: 33px;
flex-shrink: 0;
border-radius: 99px;
border: 1px solid #D8D4D5;
background: #FFF;
margin-top: 40px;
}
.student_profile{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
display: flex;
align-items: center;
height: 46px;
padding: 0px 10px;
justify-content: center;
gap: 4px;
cursor: pointer;
}
.studentactive{
    display: flex;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 50px;
background: #FFD880;
color: #0A0A0A;
}
.studentdata{
    display: flex;
width: 740px;
padding: 8px;
flex-direction: column;
align-items: flex-start;
border-radius: 8px;
border: 1px solid #F1F1F1;
background: #FFF;
margin-top: 24px;
}
.innerdivdata{
    display: flex;
padding: 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 8px;
/* background: #F9F9FC; */
}
.innerdivdatawhite{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    /* background: #fff;    */
}
.name_title{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
}
.name_student{
    color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
}
.progressinfo{
    display: flex;
width: 426px;
padding: 8px 10px;
align-items: flex-start;
gap: 10px;
border-radius: 8px;
border: 1px solid #F1F1F1;
background: #FFF;
margin: auto;
margin-top: 24px;
}
.single-progress{
    display: flex;
padding: 8px 24px 13px 8px;
flex-direction: column;
align-items: flex-start;
gap: 6px;
border-radius: 8px;
background: #F9F9FC;
}
.progressdigit{
    color: #2C292A;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 32px */
letter-spacing: 1px;
}
.definetext{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 16.8px */
letter-spacing: 0.7px;
width: 96px;
}
.lessonprogress{
    display: flex;
align-items: center;
width: 211px;
justify-content: space-between;
gap: 12px;
}
.progresslesson{
    width: 61px;
    background-color: #D9D9D9;
    border-radius: 8px;
    height: 7.25px;
    overflow: hidden;

}
.realprogress{
    height: 7.25px;
background-color: #FFD880;

}
.numberofprogress{
    color: #696969;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 184.615% */
}
.forborder{
    border-left: 1px solid #CBCBCB;
    height: 15px;
}
.numberprogresslesson{
    color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
}

  
  .studentdata:nth-child(odd) {
    background-color: #F1F1F1; /* White color for odd divs */
  }
  .studentdata:nth-child(even) {
    background-color: #F9F9FC; /* Grey color for even divs */
  }
  .addstudentback{
    color: #696969;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
letter-spacing: 0.24px;
margin-top: 65px;
margin-left: 65px;
  }
  .titleofstudent{
    color: #0A0A0A;
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 100% */
letter-spacing: 0.48px;
  }
  .titleofstudent .namecom{
    color: #374147;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.28px;
  }
  .ant-upload{
    background: #C4C4C4;
    border-radius: 9.16px !important;
  }
  .ant-upload-select{
    border: none !important;
    border-radius: 9.16px !important;
  }
  .coverofaddition{
    width: 565px;
    margin: auto;
    height: 700px;
  }
  .addmission-position{
    display: flex;
width: 564px;
align-items: center;
gap: 10px;
margin-top: 24px;
  }
  .pagelocationdone{
    display: flex;
width: 147px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
border-radius: 50px;
background: #FFD87F;
color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 171.429% */
  }
  .dots{
    display: flex;
    gap: 3px;
    align-items: center;
  }
  .addition{
    display: flex;
    width: 564px;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    margin-top: 24px;
    cursor: pointer;
  }
  .name_image{
    width: 123px;
height: 24px;
flex-shrink: 0;
border-radius: 99px;
background: #FFD87F;
color: #0A0A0A;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 200% */
padding: 10px;
margin-left: 10xp;
  }
 
  .innerdivdatawhite input{
    border: none;
  }
   .innerdivdatawhite input:focus{
    outline: none;
  }
  .innerdivdata input{
    border: none;
background-color: inherit;
  }
  .innerdivdata input:focus{
    outline: none;
    background-color: inherit;

  }
  .mobilenav{
    display: none;
  }
.selector{
    display: flex;
width: 564px;
padding: 8px;
flex-direction: column;
align-items: flex-start;
border-radius: 8px;
border: 1px solid #F1F1F1;
background: #FFF;
margin-top: 24px;
}
.mobileaddstudent{
    display: none;
}
.addstudentbtn{
    gap: 45px;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 40px;
}

.addstudentbtn span{
    color: #0A0A0A;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
letter-spacing: 0.32px;
}
.addstudentbtn .secondbtn{
    display: flex;
    width: 165px;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
background: #FBB723;
}
.savecollaborator .secondbtn{
    display: flex;
    width: 100%;
    height: 56px;
    padding: 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
background: #FBB723;
color: #fff;
margin-top: 40px;
color: #FFF;

font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
 }
 .btn-warning{
    font-weight: 500;
 }

.ant-drawer-content-wrapper  {
    width: 100% !important;
 }
 .ant-drawer-content-wrapper .drawerw{
    padding-left: 12px !important;
 }
@media (max-width:768px) {
    .desktopnav{
        display: none;
      }
    .mobilenav{
        width: 95vw;
        margin: 50px auto;
        display: flex;
    }
    .student_progress_bar{
        width: 95vw;
        margin:20px auto;
    }
    .studentdata{
        width: 95vw;
        margin: auto;
    }
    .main_student_nav{
        width: 100vw;
        margin: auto;
        height: 100vh;
    }
    .studentactive{
        width: 100px;
    }
    .Student_Nav_btn{
        gap: 80px;
    }
    .goback{
        display: none;
    }
    .studentinfo{
        margin-top: 0;
    }
    .ant-drawer-body{
        z-index: 100;
    }
    .coverofaddition{
        width: 95vw;
    }
    .addmission-position{
        gap: 25px;
        width: 95vw;
        margin: 30px auto;
    }
    .dektop{
        display: none;
    }
    .mobileaddstudent{
      display: flex;
      align-items: center;
    }
    .pagelocation {
        display: flex;
width: 46px;
height: 46px;
padding: 0px 24px;
justify-content: center;
align-items: center;
gap: 4px;
flex-shrink: 0;
border-radius: 50px;
background: #E9E9E9;
    }
    .addstudentback{
        margin: 0;
    }
    .pagelocationdone2{
        /* display: flex; */
    width: 46px;
    height: 46px;
    padding: 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #FFD87F
    }
 
}
