.studyprog{
    width: 61px;
    height: 7.25px;
    border-radius: 20px !important;
    background-color: #D9D9D9;
}
html{
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
}
a{
    text-decoration: none;
}
.stripe-grey{
    background-color: #fff !important;
}
.uploader {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background: #95999c;
    display: flex;
    flex-direction: column;

}
.uploader label{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
}
.mainbody{
    
        display: flex;
        flex-direction: column;
        text-align: center;
    
}
.buybtn{
    border: none;
    padding: 10px;
    background-color: #FBB723;
    border-radius: 8px;
    font-size: 14px;
    font-family: Inter;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
}
/* truckLoaderContainer Animation */
.truckLoaderContainer {
    height: 100vh;
    position: fixed;
    z-index: 9999;
    width: 100%;
    background:#2b292a !important;
  }
  .truckLoaderInner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }
  
  .truckLoaderInner svg {
    width: 35%;
    overflow: visible;
  }
  
  /* intro animation */
  body {
    position: relative;
  }
  .no-scroll {
    overflow: hidden;
  }
  .introContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #2c292a;
    text-align: center;
    padding: 0 2rem;
    z-index: 99999999999;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .introInner {
    width: 50%;
  }
  .introInner svg {
    width: 100%;
    overflow: visible;
  }
  
  /* keyframes for drawsvg */
  @keyframes smokeAnimation {
    from {
      stroke-dasharray: 0 100%;
      opacity: 0.5;
    }
    to {
      stroke-dasharray: 100% 100%;
      opacity: 1;
    }
  }
  .smoke {
    animation: smokeAnimation 2s linear infinite;
  }
  .headingofmodal{
    display: flex;
    width: 100%;
    padding: 10px 0px 11px 0px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #F2F0EF;
    color: #0A0A0A;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 177.778% */
  }

 .accesmodal .ant-modal-content{
    padding: 0px !important;
  }
  .footerofmodal{
    display: flex;
width: 100%;
height: 72px;
padding: 16px 24px;
justify-content: flex-end;
align-items: flex-start;
gap: 40px;
flex-shrink: 0;
border-radius: 0px 0px 16px 16px;
border-top: 1px solid var(--Neutral-color-02, #F2F0EF);
color: #2C292A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
letter-spacing: 0.28px;
  }
  .forsubmit{
    display: flex;
padding: 6px 18px;
align-items: center;
gap: 4px;
border-radius: 8px;
background: #FBB723;
color: #2C292A;
font-family: "Inter";
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 20px; /* 142.857% */
  }
  .formakingadmin{
    display: flex;
    gap: 20px;
  }
  .toggeler{
    flex-direction: column;
    gap: 10px;
    color: #000;
font-family: Inter;
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 218.182% */
  }
  .ant-switch-checked .ant-switch-inner {
    background-color: #FBB723;
  }
 
  .modalcontent2{
    color: #2C292A;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
margin-top: 10px;
  }
  .loginbtn{
    display: flex;
    padding: 14px 18px;
    align-items: center;
    gap: 4px;
    justify-content: center;
    border-radius: 8px;
    background: #FBB723;
    color: #2C292A;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 142.857% */
    margin-top: 10px;
    cursor: pointer;
  }
  a{
    color: inherit;
  }
  .ant-select-selection-placeholder {
    color: #4d4d4d;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
  }
  .ant-picker-input > input::placeholder {
    color: #696969 !important;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
  }
  .ant-space-item svg {
    color:#d8d1d1 ;
  }

  
  .ant-select-selection-item{
    color: #696969 !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 171.429% */
  }
  .econdbtn{
cursor: pointer;
  }
  .addstudentbtn span{
    cursor: pointer;
  }
  .dropdown-item{
    cursor: pointer;
  }
  .notifcationbar{
    display: flex;
width: 32px;
height: 32px;
padding: 8px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #FFD880;
color: #0A0A0A;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 160%; /* 22.4px */
letter-spacing: 0.7px;
  }
  .notificationdiv{
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .singlenotification{
    display: flex;
width: 1061px;
padding: 18px 24px;
justify-content: space-between;
align-items: center;
border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.25);
margin-top: 15px;
  }
  .activeclass{
    display: flex;
/* width: 48px;
height: 48px; */
padding: 8px;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 8px;
background: rgba(251, 183, 35, 0.20);
  }
  .activeclassed{
    display: flex;
width: 231px;
height: 48px;
padding: 8px;

align-items: flex-start;
gap: 10px;
border-radius: 8px;
background: rgba(251, 183, 35, 0.20);
  }
  .forfilter{
    display: flex;
    flex-direction: column;
  }
  .plaec{
    color: #696969;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 171.429% */
  }
  @keyframes switch-animation {
    0% {
        opacity: 1;
    }

    /* Initial state - SVG1 fully visible */
    50% {
        opacity: 0;
    }

    /* Halfway point - SVG1 fades out, SVG2 fades in */
    100% {
        opacity: 1;
    }

    /* Final state - SVG2 fully visible */
}

.animation-container {
    position: relative;
    left: 42%;
    bottom: 250px;
    max-width: 35vw;
}

.svg1,
.svg2 {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    /* Initially hidden */
}

.svg1 {
    animation: switch-animation 2s infinite;
    /* Adjust animation duration as needed */
}

.svg2 {
    animation: switch-animation 2s infinite alternate;
    /* Adjust animation duration as needed */
    animation-delay: 1s;
    /* Start after 1 second to create alternating effect */
}

@media (max-width:768px) {
 .notificationdiv{
  width: 100%;
 }
 .singlenotification{
  width: 95vw;
  margin:10px auto;
  flex-direction: column;

 }
 .singlenotification > .firstdiv{
  flex-direction: column;
 }
}
.for_name{
  width: 90%;
}
.name_student{
  width: 80%;
}
.tooler {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none; /* Ensure the tooltip doesn't interfere with mouse events */
}

.USAMap path:hover + .tooler {
  display: block;
}





path {
  pointer-events: all;
}
path:hover {
  opacity: 0.50;
  cursor: pointer;
}
/* path{
  fill: #000;
} */
.activeclassed svg path{
  stroke:#FBB723
}